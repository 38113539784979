import { AdvantagesSlideProps } from 'src/components/Carousel/types';
import { VideoPlayer } from 'src/components/VideoPlayer';

import s from './AdvantagesSlide.module.scss';

export function AdvantagesSlide({
  title,
  video,
  subtitle,
  preview,
}: AdvantagesSlideProps) {
  return (
    <div className={s.slide}>
      <div className={s.header}>
        <span className={s.number}>№1</span>
        <h3 className={s.text}>{title}</h3>
      </div>
      <VideoPlayer
        className={s.video}
        src={video}
        loop
        autoPlay
        width={395}
        previewImage={preview}
        height={160}
        muted
        playsInline
        withOutError
        videoClassName={s.video}
        link={video}
      />
      <span className={s.sub}>{subtitle}</span>
    </div>
  );
}
