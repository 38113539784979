import cn from 'classnames';
import Image from 'next/image';
import { ClientCasesProps } from 'src/components/Carousel/types';
import { Link } from 'src/components/Link';

import s from './ClientCasesSlide.module.scss';

function SlideWrapper({
  link,
  title,
  children,
}: {
  link?: string;
  title: string;
  children: React.ReactNode;
}) {
  if (link)
    return (
      <Link
        className={cn('link', s.item)}
        href={link}
        id={`case-click-link-${title}`}
        analyticsOptions={{
          action: link,
          clickZone: 'body',
          clickElement: 'cardclick',
          clickContent: title,
          uniqueId: `case-click-link-${title}`,
          transitionType: 'inside-link',
        }}
      >
        {children}
      </Link>
    );
  return <div className={cn(s.item)}>{children}</div>;
}

export function ClientCasesSlide({
  title,
  category,
  image,
  description,
  link,
}: Omit<ClientCasesProps, 'id'>) {
  const imageSrc = image
    ? image
    : 'https://cdn.cloud.ru/backend/cases/cases_logo.svg?v=1';

  return (
    <SlideWrapper title={title} link={link}>
      <div className={s.head}>
        <div className={s.headLeft}>
          <p
            className={s.title}
            data-qa="case-title"
            dangerouslySetInnerHTML={{
              __html: title,
            }}
          />
          <span className={s.category} data-qa="case-category">
            {category}
          </span>
        </div>
        <Image
          className={s.img}
          src={imageSrc}
          alt="company logo"
          data-qa="company logo"
          width={130}
          height={56}
        />
      </div>
      <p
        className={s.desc}
        data-qa="case-description"
        dangerouslySetInnerHTML={{
          __html: description,
        }}
      />
    </SlideWrapper>
  );
}
