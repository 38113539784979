const ArrowOut = ({ className = '' }: { className?: string }) => {
  return (
    <svg
      className={className}
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M14 14L14 6L6 6" stroke="currentColor" strokeWidth="1.6" />
      <path d="M6 14L13.5 6.5" stroke="currentColor" strokeWidth="1.6" />
    </svg>
  );
};

export { ArrowOut };
