import cn from 'classnames';
import { CaseProps } from 'src/components/Carousel/types';
import { HTMLComment } from 'src/components/HTMLComment';
import { ArrowOut } from 'src/uikit/icons/ArrowOut';
import { Link } from 'src/uikit/Link';

import s from './Case.module.scss';

export function CaseSlide({
  title,
  text,
  buttonTitle = 'Читать кейс',
  link,
  image,
  additionalText = 'Изображение сгенерировано нейросетью',
  withoutContent = false,
  bgClassName = '',
}: Omit<CaseProps, 'id'> & { bgClassName?: string }) {
  return (
    <div className={s.root}>
      <div className={s.wrapper}>
        {!withoutContent && (
          <div className={cn(s.content)}>
            <h3 className={s.title} data-qa="slide_title">
              {title}
            </h3>
            <p className={s.text} data-qa="slide_text">
              {text}
            </p>
            <Link
              className={cn(s.link, 'link')}
              href={link}
              rightIcon={<ArrowOut className={s.arrow} />}
              id={`carousel-cases-link-${title}`}
              isDefault
              analyticsOptions={{
                action: '/',
                clickZone: 'body',
                clickElement: 'button',
                clickContent: title,
                uniqueId: `carousel-cases-link-${title}`,
                transitionType: 'inside-link',
              }}
            >
              {buttonTitle}
            </Link>
          </div>
        )}
        <div className={s.bgWrapper}>
          <img
            className={cn(s.bg, bgClassName)}
            data-qa="slide_image"
            src={image}
            alt="Background"
          />
          <HTMLComment text="noindex" />
          <div className={s.additionalText}>{additionalText}</div>
          <HTMLComment text="/noindex" />
        </div>
      </div>
    </div>
  );
}
