import cn from 'classnames';
import { ClientProps } from 'src/components/Carousel/types';
import { NextLink } from 'src/components/NextLink';

import s from './Client.module.scss';

export function ClientSlide({
  image,
  link,
  onClick,
  targetSlideId,
}: Omit<ClientProps, 'id'>) {
  if (targetSlideId) {
    return (
      <div className={s.root} onClick={() => onClick && onClick(targetSlideId)}>
        {link ? (
          <NextLink
            data-qa="slide_link"
            href={link}
            className={cn(s.link, 'link')}
          >
            <img data-qa="slide_image" src={image} alt="client img" />
          </NextLink>
        ) : (
          <img
            data-qa="slide_image"
            className={s.image}
            src={image}
            alt="client img"
          />
        )}
      </div>
    );
  }

  return (
    <div className={s.root}>
      {link ? (
        <NextLink
          data-qa="slide_link"
          href={link}
          className={cn(s.link, 'link')}
        >
          <img data-qa="slide_image" src={image} alt="client img" />
        </NextLink>
      ) : (
        <img
          data-qa="slide_image"
          className={s.image}
          src={image}
          alt="client img"
        />
      )}
    </div>
  );
}
