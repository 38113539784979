import cn from 'classnames';
import { MainSlideProps } from 'src/components/Carousel/types';
import { NextLink } from 'src/components/NextLink';

import s from './Main.module.scss';

export function MainSlide({ title, image, link }: Omit<MainSlideProps, 'id'>) {
  return (
    <NextLink href={link} data-qa="slide_link" className={cn(s.root, 'link')}>
      <img
        className={s.bg}
        src={image}
        alt="Background"
        width={488}
        height={428}
        data-qa="slide_image"
      />
      <h2
        data-qa="slide_title"
        className={s.title}
        dangerouslySetInnerHTML={{ __html: title ? title : '' }}
      />
    </NextLink>
  );
}
