import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/effect-fade';
import 'swiper/css/pagination';
import 'swiper/css/virtual';

import cn from 'classnames';
import { forwardRef, useCallback, useRef, useState } from 'react';
import { useAnalytics } from 'src/hooks/useAnalytics';
import { Autoplay, EffectFade, Pagination, Virtual } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Swiper as SwiperTypes } from 'swiper/types';

import s from './CarouselBase.module.scss';
import {
  CaseSlide,
  CaseSlideNew,
  ClientCasesSlide,
  ClientSlide,
  MainSlide,
  ReviewSlide,
  StaffSlide,
  WarpSlide,
} from './slides';
import { AdvantagesSlide } from './slides/AdvantagesSlide';
import { TariffSlide } from './slides/TariffSlide';

function Arrow({ className = '' }: { className?: string }) {
  return (
    <svg
      data-qa="carousel-button-icon"
      className={cn(s.arrow, className)}
      width="65"
      height="64"
      viewBox="0 0 65 64"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
    >
      <path
        d="M26.0781 48L38.0781 32L26.0781 16"
        stroke="currentColor"
        strokeWidth="5.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

const ProgressBar = forwardRef<
  HTMLDivElement,
  {
    className?: string;
  }
>(({ className }, ref) => {
  return (
    <div
      className={cn(s.progressbar, className)}
      data-qa="carousel-progressbar"
    >
      <div
        className={s.progressbarInner}
        ref={ref}
        data-qa="carousel-progressbar-inner"
      />
    </div>
  );
});

ProgressBar.displayName = 'ProgressBar';

function Navigation({
  className = '',
  arrowClassName = '',
  customArrow,
  onPrevClick,
  onNextClick,
}: {
  className?: string;
  arrowClassName?: string;
  customArrow?: React.ReactNode;
  onPrevClick(): void;
  onNextClick(): void;
}) {
  const { clickAnalytics } = useAnalytics();
  const arrow = customArrow ? (
    customArrow
  ) : (
    <Arrow className={arrowClassName} />
  );

  return (
    <div className={cn(s.buttons, className)}>
      <button
        className={cn(s.button, s.buttonLeft)}
        id="carousel-prev-button"
        onClick={() => {
          onPrevClick();
          clickAnalytics({
            action: 'click',
            clickZone: 'body',
            clickElement: 'button',
            clickContent: `carousel-prev-button`,
            uniqueId: `carousel-prev-button`,
            transitionType: 'inside-link',
          });
        }}
        data-qa="carousel-button-left"
      >
        {arrow}
      </button>
      <button
        data-qa="carousel-button-right"
        className={s.button}
        id="carousel-next-button"
        onClick={() => {
          onNextClick();
          clickAnalytics({
            action: 'click',
            clickZone: 'body',
            clickElement: 'button',
            clickContent: `carousel-next-button`,
            uniqueId: `carousel-next-button`,
            transitionType: 'inside-link',
          });
        }}
      >
        {arrow}
      </button>
    </div>
  );
}

interface CarouselBaseProps {
  slidesCount: number;
  slidesPerView?: number | 'auto';
  slidesSpacing?: number;
  freeMode?: boolean;
  interval?: number;
  autoPlay?: boolean;
  customArrow?: React.ReactNode;
  effect?: 'slide' | 'fade';
  /** Использовать если слайдов меньше чем slidesPerView * 2 */
  rewind?: boolean;
  /** Использовать если слайдов >= slidesPerView * 2 */
  loop?: boolean;
  /** Устанавливает брейкпоинт >= указанного */
  breakpoints?: {
    [key: number]: {
      slidesPerView?: number | 'auto';
      spaceBetween?: number;
    };
  };
  progressBarClassName?: string;
  className?: string;
  navigationClassName?: string;
  carouselClassName?: string;
  slideClassName?: string;
  paginationBulletClassName?: string;
  arrowClassName?: string;
  speed?: number;
  withNavigation?: boolean;
  direction?: 'vertical' | 'horizontal';
  /** Необходимо передавать length массива. Выводиться будет не более 6 буллетов. */
  withPagination?: boolean;
  /** customArrow={<Arrow />} */
  withProgressBar?: boolean;
  children: React.ReactNode[];
  slidesStyles?: { width: string }[];
  withoutNavigationDirectionClassName?: boolean;
  onSlideChangeTransitionStart?: () => void;
  onSlideChangeTransitionEnd?: () => void;
  preventInteractionOnTransition?: boolean;
  autoHeight?: boolean;
  alwaysShowPaginationDots?: boolean;
}

export function CarouselBase({
  slidesCount,
  className = '',
  navigationClassName = '',
  withNavigation,
  withPagination,
  slideClassName = '',
  slidesPerView = 1,
  slidesSpacing,
  direction = 'horizontal',
  withoutNavigationDirectionClassName,
  slidesStyles,
  paginationBulletClassName = '',
  carouselClassName = '',
  arrowClassName = '',
  interval = 9000,
  breakpoints,
  loop,
  freeMode,
  rewind,
  customArrow,
  withProgressBar = false,
  autoPlay,
  children,
  effect = 'slide',
  speed = 300,
  progressBarClassName = '',
  autoHeight = false,
  alwaysShowPaginationDots = false,
}: CarouselBaseProps) {
  const [swiperRef, setSwiperRef] = useState<SwiperTypes>();
  const progressBarRef = useRef<HTMLDivElement>(null);

  const onAutoplayTimeLeft = (_: SwiperTypes, __: number, progress: number) => {
    progressBarRef.current?.style.setProperty('width', `${progress * 100}%`);
  };

  const onPrevClickHandler = useCallback(() => {
    swiperRef?.slidePrev();
  }, [swiperRef]);

  const onNextClickHandler = useCallback(() => {
    swiperRef?.slideNext();
  }, [swiperRef]);

  const isPagination =
    alwaysShowPaginationDots || (withPagination && slidesCount <= 6)
      ? {
          clickable: true,
          renderBullet: (_: number, className: string) => {
            return `<span class="${cn(
              className,
              s.bullet,
              paginationBulletClassName,
            )}"></span>`;
          },
        }
      : false;

  const isAutoPlay = autoPlay
    ? {
        delay: interval,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      }
    : false;

  const isFreeMode = freeMode
    ? {
        enabled: true,
        sticky: true,
      }
    : false;

  return (
    <div className={cn(s.root, className)}>
      <Swiper
        onSwiper={setSwiperRef}
        className={carouselClassName}
        modules={[Autoplay, Virtual, Pagination, EffectFade]}
        spaceBetween={slidesSpacing}
        slidesPerView={slidesPerView}
        breakpoints={breakpoints}
        loop={loop}
        rewind={rewind}
        pagination={isPagination}
        virtual={slidesCount > 15}
        autoplay={isAutoPlay}
        onAutoplayTimeLeft={withProgressBar ? onAutoplayTimeLeft : undefined}
        freeMode={isFreeMode}
        direction={direction}
        effect={effect}
        speed={speed}
        autoHeight={autoHeight}
      >
        {withProgressBar && (
          <ProgressBar className={progressBarClassName} ref={progressBarRef} />
        )}

        {children.map((child, index) => {
          return (
            <SwiperSlide
              className={cn(s.slide, slideClassName)}
              key={index}
              style={slidesStyles ? slidesStyles[index] : undefined}
            >
              {child}
            </SwiperSlide>
          );
        })}
      </Swiper>

      {withNavigation && (
        <Navigation
          onPrevClick={onPrevClickHandler}
          onNextClick={onNextClickHandler}
          className={cn(navigationClassName, {
            [s.buttonsVertical]:
              !withoutNavigationDirectionClassName && direction === 'vertical',
          })}
          customArrow={customArrow}
          arrowClassName={arrowClassName}
        />
      )}
    </div>
  );
}

CarouselBase.Warp = WarpSlide;
CarouselBase.Main = MainSlide;
CarouselBase.Case = CaseSlide;
CarouselBase.CaseNew = CaseSlideNew;
CarouselBase.Client = ClientSlide;
CarouselBase.Staff = StaffSlide;
CarouselBase.Review = ReviewSlide;
CarouselBase.Staff = StaffSlide;
CarouselBase.ClientCases = ClientCasesSlide;
CarouselBase.Advantages = AdvantagesSlide;
CarouselBase.Tariff = TariffSlide;
