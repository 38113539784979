import { ReviewProps } from 'src/components/Carousel/types';

import s from './Review.module.scss';

export function ReviewSlide({
  title,
  description,
  text,
}: Omit<ReviewProps, 'id'>) {
  return (
    <div className={s.root}>
      <div className={s.wrapper}>
        <div className={s.content}>
          <div className={s.author}>
            <h3 className={s.title} data-qa="slide_title">
              {title}
            </h3>
            {description && (
              <p className={s.text} data-qa="slide_text">
                {description}
              </p>
            )}
          </div>
          <p className={s.review}>«{text}»</p>
        </div>
      </div>
    </div>
  );
}
