import PropTypes from 'prop-types';
import { createRef, useEffect } from 'react';

export const HTMLComment = ({ text }: { text: string }) => {
  const ref: React.RefObject<HTMLSpanElement> = createRef();

  useEffect(() => {
    let el: any = null;
    let parent: any = null;
    let comm: any = null;

    if (ref.current) {
      el = ref.current;
      parent = el.parentNode;
      comm = document.createComment(` ${text.trim()} `);

      try {
        if (parent && parent.contains(el)) {
          parent.replaceChild(comm, el);
        }
      } catch (error) {}
    }

    return () => {
      if (parent && el && comm) {
        parent.replaceChild(el, comm);
      }
    };
  }, [ref, text]);

  return <span ref={ref} style={{ display: 'none' }} />;
};

HTMLComment.propTypes = {
  text: PropTypes.string,
};
