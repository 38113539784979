import cn from 'classnames';
import { LinkWithAnchor } from 'src/components/ConstructorBlocks/LinkWithAnchor';
import { useWindowWidth } from 'src/hooks/useWindowWidth';

import s from './CaseSlideNew.module.scss';

function CasesSlideImage({ image }: { image: string }) {
  return (
    <div className={s.imageWrapper}>
      <img src={image} alt="Logo" className={s.image} />
    </div>
  );
}

type SlideVariant = 'case' | 'product' | 'warp';

interface CaseSlideNewProps {
  title: string;
  text: string;
  buttonTitle?: string;
  buttonLink?: string;
  onButtonClick?: () => void;
  classNameLink?: string;
  image: string;
  buttonID?: string;
  slideVariant?: SlideVariant;
  slideClassName?: string;
  target?: string;
}

export function CaseSlideNew({
  title,
  text,
  buttonTitle,
  image,
  buttonLink,
  onButtonClick,
  buttonID,
  classNameLink,
  slideVariant = 'case',
  slideClassName = '',
  target,
}: CaseSlideNewProps) {
  const {
    windowWidth,
    breakpoints: { MD },
  } = useWindowWidth();

  return (
    <div
      className={cn(s.root, {
        [s.case]: slideVariant === 'case',
        [s.product]: slideVariant === 'product',
        [s.warp]: slideVariant === 'warp',
      })}
    >
      <div className={cn(s.main, slideClassName)}>
        <div className={s.content}>
          <span className={s.title}>{title}</span>
          <div className={s.text} dangerouslySetInnerHTML={{ __html: text }} />
          {((buttonTitle && buttonLink) || (buttonTitle && onButtonClick)) && (
            <LinkWithAnchor
              buttonID={buttonID}
              buttonTitle={buttonTitle}
              link={buttonLink}
              target={target}
              buttonOnClickAction={onButtonClick}
              className={classNameLink}
            />
          )}
        </div>
        {windowWidth && image && windowWidth <= MD && (
          <CasesSlideImage image={image} />
        )}
      </div>
      {windowWidth && image && windowWidth > MD && (
        <CasesSlideImage image={image} />
      )}
    </div>
  );
}
