import cn from 'classnames';
import { useState } from 'react';
import { WarpSlideProps } from 'src/components/Carousel/types';
import { Link } from 'src/components/Link';
import { Tag } from 'src/uikit/Tag';
import { sliceText } from 'src/utils/sliceText';

import s from './Warp.module.scss';

export function WarpSlide({
  title,
  cover,
  createdAt,
  datePosition,
  categoryTitle,
  href,
  tagAppearance,
}: Omit<WarpSlideProps, 'id' | 'categories'>) {
  const [imgSrc, setImgSrc] = useState(cover);
  const isReversed = datePosition === 'top';

  return (
    <article className={s.article}>
      <header className={s.header}>
        <img
          src={imgSrc}
          alt={title}
          className={s.image}
          width={395}
          height={240}
          onError={() => setImgSrc('/plugs/warp-header-default.png')}
        />
        {categoryTitle && (
          <Tag
            appearance={tagAppearance ?? Tag.appearances['gray-90']}
            variant={Tag.variants.Primary}
            className={s.badge}
          >
            {categoryTitle}
          </Tag>
        )}
      </header>

      <section className={cn(s.content, { [s.reversed]: isReversed })}>
        <h2
          className={cn(s.title, {
            [s.titleBottom]: isReversed,
          })}
        >
          <Link
            href={href}
            id={`warp-slide-link-${title}`}
            analyticsOptions={{
              action: href,
              clickZone: 'body',
              clickElement: 'textclick',
              clickContent: title,
              uniqueId: `warp-slide-link-${title}`,
              transitionType: 'inside-link',
            }}
          >
            {sliceText(title, 97)}
          </Link>
        </h2>

        {createdAt && (
          <time className={cn(s.date, { [s.dateTop]: isReversed })}>
            {createdAt}
          </time>
        )}
      </section>
    </article>
  );
}
