import { type TariffSlide } from 'src/types';

import s from './TariffSlide.module.scss';

export function TariffSlide({ title, price, features }: TariffSlide) {
  return (
    <div key={title} className={s.root}>
      <h4 className={s.title}>{title}</h4>
      <p className={s.price}>{price}</p>
      <div className={s.featureList}>
        {features.map((f) => (
          <div key={f.key} className={s.feature}>
            <span>{f.key}</span>
            <p>{f.value}</p>
          </div>
        ))}
      </div>
    </div>
  );
}
