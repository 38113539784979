import Image from 'next/image';
import { StaffProps } from 'src/components/Carousel/types';

import s from './Staff.module.scss';

export function StaffSlide({ name, staff, image }: Omit<StaffProps, 'id'>) {
  return (
    <div className={s.root}>
      <Image
        data-qa="slide_image"
        className={s.image}
        src={image}
        alt="staff img"
        width={292}
        height={440}
      />
      <div className={s.description}>
        <div className={s.name} dangerouslySetInnerHTML={{ __html: name }} />
        <div className={s.staff}>{staff}</div>
      </div>
    </div>
  );
}
